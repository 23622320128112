@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


/* .background-image {
  background-image: url(./piede\ a.png);
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #a742f5;
  background-blend-mode: darken; 
} */
.background-image {
  background-image: url(logo_4.png);
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #8534C4;
  background-blend-mode: darken;
  z-index: -1;
}

.home-page-text {
  text-align: center;
  color: #fff;
  margin-top: -40vh;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -10vh;
}



.home-page-text h1 {
  font-size: 60px;
  margin-bottom: 20px;
  font-family: 'Pacifico', cursive;
}

.home-page-text p {
  font-size: 24px;
  margin-bottom: 40px;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.home-page-text a {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
}

.home-page-text a:hover {
  color: #ccc;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.home-page-text a {
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.home-page-text a:after {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  background-color: none;
  transition: width 0.3s ease;
}

.home-page-text a:hover:after {
  width: 100%;
}

/* Add this style to remove the extra clickable space */
.home-page-text a span {
  padding: 0;
  margin: 0;
}

.TermsAndConditions {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  position: relative;
  z-index: 1;
  overflow-y: auto;
  height: 80vh;
  scrollbar-width: none;
  color: white;
}

.TermsAndConditions h4 {
  font-size: 18px;
  margin-top: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-decoration-line: underline;
}

.TermsAndConditions p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
}

.TermsAndConditions ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.TermsAndConditions li {
  margin-bottom: 10px;
}

.TermsAndConditions a {
  text-decoration: none;
  color: #ccc;
}

.TermsAndConditions a:hover {
  color: #ccc;
}